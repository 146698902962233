<template>
  <news-form
    v-if="news"
    action="update"
    :loading="loading"
    :updating="updating"
    :data="news"
    :error-messages="errorMessages"
    :handle-submit="handleFormSubmit"
    :handle-discard="handleDiscard"
    @generate:slug="val => news.slug = val"
  />
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, watch, getCurrentInstance } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import useNewsView from './useNewsView'

import NewsForm from './news-form/NewsForm.vue'

export default {
  components: {
    NewsForm,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { route } = useRouter()

    const {
      news,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useNewsView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const handleFormSubmit = formData => updateData(formData)
    const handleDiscard = () => vm.$router.replace({ name: 'news-view', params: { id: route.value.params.id } })

    return {
      news,
      loading,
      updating,
      updateData,

      errorMessages,

      handleFormSubmit,
      handleDiscard,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
